import styled, { css, StyledComponent } from '@xstyled/styled-components'
import { breakpoints, variant } from '@xstyled/system'
/* eslint-disable indent */

const QuoteWrapVariant = variant({
  default: 'light',
  prop: 'variant',
  variants: {
    dark: css`
      color: dark;
    `,
    light: css`
      color: light;
    `,
  },
})

export const QuoteWrap: StyledComponent<
  'div',
  Record<string, unknown>,
  { variant: string; maxWidth: string },
  never
> = styled.div<{ maxWidth: string | null }>`
  opacity: 0;
  font-family: primary;
  /* todo: use rem sizes when the appropriate size is available */
  font-size: 2.6rem;
  line-height: 4rem;
  max-width: ${(props) => props.maxWidth};
  ${QuoteWrapVariant};
`

export const TitleQuoteWrap: StyledComponent<
  'div',
  Record<string, unknown>,
  { variant: string; maxWidth: string },
  never
> = styled.div<{ maxWidth: string | null }>`
  opacity: 0;
  font-family: title;
  /* todo: use rem sizes when the appropriate size is available */

  max-width: ${(props) => props.maxWidth};

  > span {
    text-transform: uppercase;
    font-size: 48px;
    line-height: 48px;
    ${breakpoints({
      md: css`
        font-size: 110px;
        line-height: 11rem;
      `,
    })}
  }
  h5 {
    font-family: primary;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 20px;
    display: none;
    ${breakpoints({
      md: css`
        display: block;
      `,
    })}
  }

  ${QuoteWrapVariant};
`

const SeparatorVariant = variant({
  default: 'light',
  prop: 'variant',
  variants: {
    dark: css`
      background-color: dark;
    `,
    light: css`
      background-color: light;
    `,
  },
})

export const Separator: StyledComponent<
  'div',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.div`
  height: 1px;
  margin: 3.35rem 0;
  ${SeparatorVariant};
  width: 5.5rem;
  transform-origin: left;
`
export const NoSeparator = styled.div`
  height: 1px;
  margin: 1.35rem 0;
  width: 5.5rem;
  transform-origin: left;
  opacity: 0;
`

const NameVariant = variant({
  default: 'light',
  prop: 'variant',
  variants: {
    dark: css`
      color: dark;
    `,
    light: css`
      color: light;
    `,
  },
})

export const Name: StyledComponent<
  'div',
  Record<string, unknown>,
  { variant: string },
  never
> = styled.div`
  overflow: hidden;
  font-family: primary;
  font-size: 16px;
  line-height: 16px;
  ${NameVariant};

  span {
    display: block;
    span {
      margin-top: 10px;
    }
  }
`
