import { breakpoints } from '@xstyled/system'
import styled, { css } from '@xstyled/styled-components'
import proportion from '@/modules/utils/proportion'

export const ExcerptLinkWrap = styled.a`
  align-items: center;
  color: light;
  display: flex;
  justify-content: space-between;
  ${breakpoints({
    xs: css`
      margin-top: ${proportion(18, 'handheld')};
    `,
  })}
  &:hover {
    & > div:before {
      transform: scaleX(1);
    }
  }
`

export const ExcerptLinkLabel = styled.div`
  font-family: title;
  font-size: 3.4rem;
  letter-spacing: 0;
  line-height: 3.4rem;
  position: relative;
  text-transform: uppercase;

  &:before {
    background-color: light;
    bottom: 0;
    content: '';
    height: 0.2rem;
    left: 0;
    opacity: 0.6;
    position: absolute;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.2s ease-in-out;
    width: 100%;
  }
`

export const ExcerptLinkExcerpt = styled.div`
  font-family: primary;
  font-size: md;
`
