import React from 'react'
import PropTypes from 'prop-types'
import { ExcerptLinkListWrap } from './styled'
import ExerptLink, { excerptLinkType } from '../ExcerptLink'

type listDataType = excerptLinkType[]

const ExcerptLinkList: React.FC<{
  listData: listDataType
}> = ({ listData }) => (
  <ExcerptLinkListWrap>
    {listData.map((data) => (
      <ExerptLink excerptLink={data} />
    ))}
  </ExcerptLinkListWrap>
)

ExcerptLinkList.propTypes = {
  listData: PropTypes.array.isRequired,
}

export default ExcerptLinkList
