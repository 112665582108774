import React from 'react'
import { ExcerptLinkWrap, ExcerptLinkLabel, ExcerptLinkExcerpt } from './styled'

export interface excerptLinkType {
  label: string
  excerpt?: string
  linkUrl: string
}

const ExcerptLink: React.FC<{ excerptLink: excerptLinkType }> = ({
  excerptLink,
}) => (
  <ExcerptLinkWrap href={excerptLink.linkUrl} target="_blank">
    <ExcerptLinkLabel>{excerptLink.label}</ExcerptLinkLabel>
    <ExcerptLinkExcerpt>{excerptLink.excerpt}</ExcerptLinkExcerpt>
  </ExcerptLinkWrap>
)

export default ExcerptLink
