import React from 'react'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import { pageFadeInOut } from '@/modules/animationVariants'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import ExcerptLinkListContainer from './contact/ExcerptLinkListContainer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import QuoteContainer from './contact/QuoteContainer'

const IndexPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Page
      content={
        <PageWrapper>
          <WithSlideShowCheck
            fullWidth
            mobileContent={[
              <>
                <Text
                  pretitle={t(
                    'global.contact_super',
                    'fill copy key: global.contact_super',
                  )}
                  title={t(
                    'global.contact_title',
                    'fill copy key: global.contact_title',
                  )}
                  text={t(
                    'global.contact_body',
                    'fill copy key: global.contact_body',
                  )}
                  links={[
                    {
                      link: t(
                        'global.contact_cta',
                        'fill copy key: global.contact_cta',
                      ),
                      url: '/home/followup/meet',
                      variant: 'all',
                    },
                  ]}
                  withIndicators={<ExcerptLinkListContainer />}
                />
              </>,
            ]}
          >
            <Box row>
              <Box col={0.4}>
                <Text
                  pretitle={t(
                    'global.contact_super',
                    'fill copy key: global.contact_super',
                  )}
                  title={t(
                    'global.contact_title',
                    'fill copy key: global.contact_title',
                  )}
                  text={t(
                    'global.contact_body',
                    'fill copy key: global.contact_body',
                  )}
                  links={[
                    {
                      link: t(
                        'global.contact_cta',
                        'fill copy key: global.contact_cta',
                      ),
                      url: '/home/followup/meet',
                      variant: 'all',
                    },
                  ]}
                  withIndicators={<ExcerptLinkListContainer />}
                />
                {/* {inProp && <ExcerptLinkListContainer />} */}
              </Box>
              <Box
                col={0.6}
                display="flex"
                alignItems="flex-end"
                justifyContent="flex-end"
              >
                <QuoteContainer />
              </Box>
            </Box>
          </WithSlideShowCheck>
        </PageWrapper>
      }
      settings={{
        backgroundColor: '#113f4f',
        contentAnimationVariants: pageFadeInOut(),
        useCircularWipe: false,
      }}
    />
  )
}

export default IndexPage
