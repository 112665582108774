import React, {
  useLayoutEffect,
  useRef,
  ReactNode,
  useState,
  useEffect,
} from 'react'
import PropTypes from 'prop-types'
import {
  Name,
  QuoteWrap,
  Separator,
  NoSeparator,
  TitleQuoteWrap,
} from './styled'
import gsap from 'gsap'
import { SplitText } from 'gsap/dist/SplitText'
import { getIsMobile } from '@/modules/utils/getMobile'

const Quote: React.FC<{
  inProp?: boolean
  name?: string
  jobTitle?: string
  children: ReactNode
  maxWidth?: string
  variant?: string
  noSeperator?: boolean
  titleComponent?: boolean
  preTitle?: string
}> = ({
  name,
  jobTitle,
  children,
  maxWidth,
  variant,
  inProp = false,
  noSeperator,
  titleComponent,
  preTitle,
}) => {
  const containerRef = useRef(null)
  const tweenRef = useRef(null)
  const quoteRef = useRef(null)
  const nameRef = useRef(null)
  const separatorRef = useRef(null)
  const [ready, setReady] = useState(false)
  const ST = useRef(null)
  const [isMobile, setIsMobile] = useState(getIsMobile())

  useEffect(() => {
    if (ready) {
      if (tweenRef.current) {
        tweenRef.current.kill()
      }
      tweenRef.current = gsap.delayedCall(1, () => {
        const tl = gsap.timeline({})

        tl.set(containerRef.current, {
          opacity: 1,
        })

        tl.fromTo(
          ST.current.chars,
          1,
          {
            opacity: 0,
          },
          {
            opacity: 1,
            stagger: 0.014,
            ease: 'sine.inOut',
          },
        )
        if (separatorRef.current) {
          tl.fromTo(
            separatorRef.current,
            0.5,
            {
              scaleX: 0,
            },
            { scaleX: 1 },
            1,
          )
        }

        tl.fromTo(
          nameRef.current,
          0.5,
          {
            y: 16,
          },
          { y: 0 },
          1,
        )
      })
    }
  }, [ready, ST.current])

  useEffect(() => {
    const onResize = () => setIsMobile(getIsMobile())
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [])

  useEffect(() => {
    ST.current = new SplitText(quoteRef.current, { type: 'words,chars' })
  }, [quoteRef.current])

  useLayoutEffect(() => {
    if (!inProp) {
      return
    }
    setReady(true)
  }, [inProp])

  return !titleComponent ? (
    <QuoteWrap variant={variant} maxWidth={maxWidth} ref={containerRef}>
      <span ref={quoteRef}>{children}</span>
      {!noSeperator ? (
        <Separator variant={variant} ref={separatorRef} />
      ) : (
        <NoSeparator />
      )}
      <Name variant={variant}>
        <span ref={nameRef}>
          {name} {jobTitle && `– ${jobTitle}`}
        </span>
      </Name>
    </QuoteWrap>
  ) : (
    <TitleQuoteWrap variant={variant} maxWidth={maxWidth} ref={containerRef}>
      <h5>{preTitle}</h5>
      <span ref={quoteRef}>{children}</span>
      {!noSeperator ? (
        <Separator variant={variant} ref={separatorRef} />
      ) : (
        <NoSeparator />
      )}
      <Name variant={variant}>
        <span ref={nameRef}>
          {name} {jobTitle && !isMobile && '- '}
          {jobTitle && !isMobile && `${jobTitle}`}
          {jobTitle && isMobile && <span>{jobTitle}</span>}
        </span>
      </Name>
    </TitleQuoteWrap>
  )
}

Quote.defaultProps = {
  jobTitle: null,
  maxWidth: null,
  variant: 'light',
  noSeperator: false,
  titleComponent: false,
  preTitle: null,
  name: null,
}

Quote.propTypes = {
  jobTitle: PropTypes.string,
  maxWidth: PropTypes.string,
  name: PropTypes.string,
  variant: PropTypes.string,
  noSeperator: PropTypes.bool,
  titleComponent: PropTypes.bool,
  preTitle: PropTypes.string,
}

export default Quote
