import React, { useRef, useEffect } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ExcerptLinkList from '@/components/ExcerptLinkList'
import gsap from 'gsap'
import styled from '@xstyled/styled-components'

const ExcerptLinkListContainer: React.FC = () => {
  const { t } = useTranslation()
  const linkRef = useRef(null)

  const animateIn = () => {
    gsap.fromTo(linkRef.current, 1, { alpha: 0 }, { alpha: 1, delay: 1 })
  }

  useEffect(() => {
    animateIn()
  }, [])

  return (
    <ExcerptLinkListC ref={linkRef}>
      <ExcerptLinkList
        listData={[
          {
            excerpt: t(
              'global.contact_email_body',
              'fill copy key: global.contact_email_body',
            ),
            label: t(
              'global.contact_email_title',
              'fill copy key: global.contact_email_title',
            ),
            linkUrl: 'mailto:info@ohpen.nl',
          },
          {
            excerpt: t(
              'global.contact_callus_body',
              'fill copy key: global.contact_callus_body',
            ),
            label: t(
              'global.contact_callus_title',
              'fill copy key: global.contact_callus_title',
            ),
            linkUrl: 'tel:+31207221000',
          },
          {
            excerpt: t(
              'global.contact_press_body',
              'fill copy key: global.contact_press_body',
            ),
            label: t(
              'global.contact_press_title',
              'fill copy key: global.contact_press_title',
            ),
            linkUrl: 'mailto:press@ohpen.nl',
          },
          {
            excerpt: t(
              'global.contact_linkedin_body',
              'fill copy key: global.contact_linkedin_body',
            ),
            label: t(
              'global.contact_linkedin_title',
              'fill copy key: global.contact_linkedin_title',
            ),
            linkUrl: 'https://www.linkedin.com/company/ohpen',
          },
        ]}
      />
    </ExcerptLinkListC>
  )
}

export const ExcerptLinkListC = styled.div`
  opacity: 0;
  margin-bottom: 40px;
`

ExcerptLinkListContainer.propTypes = {}

ExcerptLinkListContainer.defaultProps = {}

export default ExcerptLinkListContainer
