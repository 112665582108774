import React from 'react'
import Quote from '@/components/Quote'

const QuoteContainer: React.FC = () => (
  <Quote variant="light" name="Matthijs Aler" jobTitle="CEO" maxWidth="55rem">
    Ohpen is active al around the world with offices in Amsterdam, London and
    barcalona
  </Quote>
)

QuoteContainer.propTypes = {}

QuoteContainer.defaultProps = {}

export default QuoteContainer
